'use client';
/**
 * This GlobalError component serves as a fallback UI for uncaught errors in your Next.js application.
 * When a runtime error occurs that isn't caught by more specific error boundaries, this component will be rendered, providing users with information about the error and an option to attempt recovery.
 */
export default function GlobalError({
  error,
  reset
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  console.log(error, 'error from GlobalError');

  return (
    <html>
      <body>
        <h2>Something went wrong!</h2>
        <p className="t-14-15 font-normal color-ba-gray-900">
          {error?.message}
        </p>
        <p className="t-14-15 font-normal color-ba-gray-900">{error?.name}</p>
        <p className="t-14-15 font-normal color-ba-gray-900">{error?.stack}</p>
        <p className="t-14-15 font-normal color-ba-gray-900">{error?.digest}</p>
        <button onClick={() => reset()}>Try again</button>
      </body>
    </html>
  );
}
